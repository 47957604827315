<template>
<div id="app">
  <router-view  class="number"/>
</div>
</template>

<script>


export default {
 name: 'App'
};
</script>

<style lang="less">

#app{
  height: 100%;
  width: 100%;
}

</style>
