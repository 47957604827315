import Vue from 'vue'
import Router from 'vue-router'

//组件模块
import Number from './components/Number.vue'

Vue.use(Router)



export default new Router({
    mode: 'history',
    routes: [{
            path: '/number',
            name: 'number',
            component: Number
        },

    ]
})