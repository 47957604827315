<template>
  <div class="subject" v-if="number==='2'">
    <div class="subject_interior">
      <div class="subject_main">
        <div class="header">
          <div class="header-bg">
            <h2>{{headline.title}}</h2>
          </div>
        </div>
        <div class="chart">
          <div class="chart_text">
            <div>{{addUp}}：</div>
            <ICountUp :endVal="addUpMoney" :options="options" />
          </div>
          <div class="chart_top">
            <div class="chart_top_lfet">
              <div class="chart_customer">
                <div class="chart_customer_text-bg">
                  <h3>{{business_top.title}}</h3>
                </div>
              </div>
              <div id="main"></div>
              <div class="chart_customer_bottom">
                <div class="chart_customer_text-bg_bottom">
                  <h3>{{business_top.count}}</h3>
                </div>
              </div>
            </div>
            <div class="chart_top_right">
              <div class="chart_customer">
                <div class="chart_customer_text-bg">
                  <h3>{{week_cost.title}}</h3>
                </div>
              </div>
              <div id="main1"></div>
              <div class="chart_customer_bottom">
                <div class="chart_customer_text-bg_bottom">
                  <h3>{{week_cost.count}}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="tables">
            <div class="tables_top">
              <div class="chart_customer">
                <div class="chart_customer_text-bg">
                  <h3>{{industry_top.title}}</h3>
                </div>
              </div>
              <div id="main2"></div>
              <div class="chart_customer_bottom">
                <div class="chart_customer_text-bg_bottom">
                  <h3>{{industry_top.count}}</h3>
                </div>
              </div>
            </div>
            <div class="table">
              <div class="chart_customer">
                <div class="chart_customer_text-bg">
                  <h3>{{creativeTopText.title}}</h3>
                </div>
              </div>
              <div class="table_video">
                <div class="video_one">
                  <video
                    class="video_open"
                    muted
                    autoplay="autoplay"
                    loop="loop"
                    x5-playsinline="true"
                    playsinline="true"
                    webkit-playsinline="true"
                    x-webkit-airplay="allow"
                    preload="auto"
                    x5-video-player-type="h5"
                    x5-video-player-fullscreen="true"
                    :src="this.originaDataUrl"
                    type="video/mp4"
                    v-show="originaDataFormat=='MP4'"
                  ></video>
                  <img :src="originaDataUrl" class="img_text" v-show="originaDataFormat=='JPEG'" />
                </div>
                <div class="video_chart">
                  <div
                    v-for="(item,index) in creative_top.list"
                    :key="index"
                    :class="{active: isActive==item.url}"
                    class="video_text"
                  >
                    <h3 class="video_wz">{{item.name}}</h3>
                  </div>
                  <div class="chart_customer_bottom">
                    <div class="chart_customer_text-bg_bottom">
                      <h3>{{creativeTopText.count}}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="top">
          <div class="top_bg">
            <span>SHUN DATA v1.0</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts/lib/echarts";
import ICountUp from "vue-countup-v2";
import { getClient } from "../api/index";
export default {
  name:'number',
  components: {
    ICountUp
  },
  data() {
    return {
      //后台需要数据
      params: {
        company_id: "2",
        c: "getstatisticsscreen",
        m: "report",
        mode: "allv2",
        platform: "1"
      },
      number:String,
      headline: Object, //后台数据
      isActive: String, //top轮换条件
      addUp: String, //累计消耗标题
      addUpMoney: 0, //累计消耗总数
      business_top: Object, //客户top排名
      clientData: Array, //客户top排名公司
      clientDataList: Array, //客户top排名公司数据
      week_cost: Object, //一周top排名
      weekData: Array, //一周投放消耗时间
      weekDataList: Array, //一周投放消耗数据
      industry_top: Object, //行内投放消耗
      industryData: Array, //行内投放消耗公司
      industryDataList: Array, //行内投放消耗数据
      creative_top: Object, //创意top排名
      creativeTopText: Object, //创意top排名标题
      originaData: Array, //创意top排名数据
      originaDataUrl: String, //创意top排名数据视频地址
      originaDataFormat: String, //创意top排名数据视频地址
      num: 0,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: ""
      }
    };
  },
  methods: {
    //柱状图
    getEchartsColumnar() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("main"));
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: ""
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          formatter: "{a} {b} : {c} "
        },
        grid: {
          left: "1%",
          right: "1%",
          bottom: "1%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          show: false
        },
        yAxis: {
          type: "category",
          data: this.clientData,
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: "#fff"
            }
          }
        },
        series: [
          {
            name: "",
            type: "bar",
            data: this.clientDataList,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "right",
                  color: "#fff"
                }
              }
            }
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    //折线图
    getEchartsLine() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("main1"));
      // 指定图表的配置项和数据
      var option = {
        xAxis: {
          type: "category",
          data: this.weekData,
          axisLabel: {
            color: "#fff"
          }
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#fff"
          }
        },
        series: [
          {
            data: this.weekDataList,
            type: "line",
            itemStyle: {
              normal: {
                label: {
                  show: true, //自动显示数据 ，无需鼠标滑动才显示数据
                  textStyle: {
                    fontWeight: "bolder",
                    fontSize: "12",
                    color: "#1E90FF"
                  }
                },
                lineStyle: {
                  color: "#8cd5c2"
                }
              }
            }
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    //饼图
    getEchartsCake() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("main2"));
      // 指定图表的配置项和数据
      var option = {
        title: {
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} {b} : {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: this.industryData,
          textStyle: {
            color: "#fff",
            fontSize: 14
          }
        },
        color: [
          "#1E90FF",
          "#00FFFF",
          "#98FB98",
          "#FFD700",
          "#FF6347",
          "#B0C4DE"
        ],
        series: [
          {
            name: "",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: this.industryDataList,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 10,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            },
            itemStyle: {
              normal: {
                label: {
                  formatter: "{d}%"
                }
              },
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            },
            labelLine: {
              normal: {
                length: 5
              }
            }
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    onReady: function(instance, CountUp) {
      const that = this;
      instance.update(that.endVal);
    },
    //获取后台数据
    getData() {
      getClient(this.params)
        .then(res => {
          console.log(res);
          this.headline = res.data;
          //累计消耗总数
          this.addUp = res.data.title_info.cost_desc;
          this.addUpMoney = res.data.title_info.cost;
          //top排名数据
          let list = res.data.business_top.list;
          this.business_top = res.data.business_top.info;

          this.clientData = list
            .map(item => {
              return item.name;
            })
            .reverse();
          this.clientDataList = list
            .map(item => {
              return item.value;
            })
            .reverse();
          //一周消耗数据
          let weekList = res.data.week_cost.list;
          this.week_cost = res.data.week_cost.info;
          this.weekData = weekList
            .map(item => {
              return item.date;
            })
            .reverse();
          this.weekDataList = weekList
            .map(item => {
              return item.value;
            })
            .reverse();
          //行内消耗数据
          let industryList = res.data.industry_top.list;
          this.industry_top = res.data.industry_top.info;
          this.industryData = Object.values(industryList)
            .map(item => {
              return item.name;
            })
            .reverse();
          this.industryDataList = Object.values(industryList)
            .map(item => {
              return item;
            })
            .reverse();
          //top创意
          this.creative_top = res.data.creative_top;
          this.creativeTopText = res.data.creative_top.info;
          this.originaData = res.data.creative_top.list;
          this.originaDataUrl = this.originaData[0].url;
          this.originaDataFormat = this.originaData[0].format;
          this.isActive = this.originaData[0].url;
          console.log(this.originaDataUrl);

          this.getEchartsColumnar();
          this.getEchartsLine();
          this.getEchartsCake();
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  watch: {
    //监听视频轮换
    num(val, old) {
      this.isActive = this.originaData[val].url;
      this.originaDataFormat = this.originaData[val].format;
      this.originaDataUrl = this.originaData[val].url;
    }
  },
  created() {
    //判断地址栏中参数
    this.number=this.$route.query.company_id  
    this.getData();
  },
  mounted() {
    //定时更新数据
    const timer = setInterval(() => {
      //定时获取数据
      this.getData();
    }, 1000 * 3600);
    const times = setInterval(() => {
      //定时更换视频
      this.num += 1;
      if (this.num > 2) {
        return (this.num = 0);
      }
    }, 1000 * 40);
    // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
    this.$once("hook:beforeDestroy", () => {
      clearInterval(timer);
      clearInterval(times);
    });
  }
};
</script>

<style lang="less">
@import "../style/style.css";
</style>